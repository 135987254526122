@tailwind base;

/* Write your own custom base styles here */
ul {
  list-style: disc;
  margin: 1em 0;
  padding-left: 40px;
}

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@300&display=swap');

/* Globals */
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  @apply text-black;
  font-size: 16px;
}

h2 {
  @apply uppercase font-heading text-navy mb-2 text-sm font-bold;
}

h3 {
  @apply text-md font-bold;
}

p {
  @apply font-content text-sm leading-tight;
}

p + p {
  @apply mt-2;
}

section {
  @apply my-10;
}

label,
input {
  @apply font-nav font-normal;
}

input {
  @apply rounded-2xl w-full px-2 py-1 shadow-lg border-2 focus:border-pink focus:outline-none;
  border-color: #edecec;
}

.button {
  @apply rounded-2xl w-full p-1 shadow-lg border-2 focus:border-pink focus:outline-none bg-pink text-white font-nav disabled:opacity-10;
  border-color: #edecec;
  height: 40px;
}

label {
  display: inline-block;
}

label > input {
  @apply mt-1;
}

label + label {
  @apply mt-2;
}

/* Textarea & Inputs */
input,
textarea {
  @apply transition-all focus:border-pink outline-none;
  border-color: #edecec;
}

input:focus-visible,
textarea:focus-visible {
  @apply outline-none;
}

/* Select Input */
select,
.select {
  @apply rounded-2xl py-1 pr-4 pl-2 shadow-lg border focus:border-pink focus:outline-none appearance-none relative transition-all h-5;
  border-color: #edecec;
}

/* Button */
button {
  @apply bg-pink text-white font-poppins font-medium disabled:opacity-10 rounded-full py-1 px-2 outline-none;
  border-color: #edecec;

  &:focus,
  &:focus-visible {
    @apply outline-none;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.text-btn {
  @apply bg-transparent text-gray border-none hover:text-pink h-auto;
}

/* Write your own custom component styles here */
.btn-blue {
  @apply px-4 py-2 font-bold text-white rounded;
}

.app-header {
  @apply p-4;
  height: 120px;
}

.sidebar {
  @apply py-6 flex flex-col;
  background: linear-gradient(41.15deg, #4aa2cb 59.6%, #c7327c 199.86%);
  width: 215px;
}

.sidebar-link {
  @apply text-white py-2 px-4 font-nav;
}

.sidebar-link-active {
  @apply text-blue bg-white;
}

/* Join the community section */
.cta h3 {
  @apply text-pink font-nav font-normal mb-3;
}

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Your own custom utilities */
